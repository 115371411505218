.navbar-brand img {
  max-width: 280px; /* Default size for larger viewports */
  height: auto; /* Keep aspect ratio */
}

@media (min-width: 768px) {
  .text-center {
    padding-left: 60px; /* Adjust this value to your needs */
    padding-right: 60px;
  }
}

@media (min-width: 1400px) {
  .text-center {
    padding-left: 120px; /* Adjust this value to your needs */
    padding-right: 120px;
  }
}

@media (max-width: 991px) {
  img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 400px) {
  .navbar-brand img {
    width: 240px; /* Adjusted size for smaller viewports */
  }
  .carousel-control-prev,
  .carousel-control-next {
    height: 32px !important; /* Even smaller height for very small screens */
    width: 32px !important; /* Even smaller width for very small screens */
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 16px; /* Smaller icon size for very small screens */
    height: 16px; /* Smaller icon size for very small screens */
  }
  .carousel-indicators button {
    height: 2px !important; /* Even smaller size for very small screens */
    width: 20px !important; /* Even smaller size for very small screens */
  }
}

.nav-link {
  font-size: 1.5em;
}

.active {
  font-weight: bold;
}

.content-wrapper {
  background-color: #e7feae;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Adjust the shadow as needed */
  padding: 20px;
}

.fa-heart {
  color:#e5307d;
}

.footer {
  padding: 20px 0;
  text-align: center !important;
}

.footer-icon {
  margin-right: 10px;
}

.nav-link,
.phone:link {
  color: #e5307d;
  text-decoration: none;
  
}

.nav-link,
.phone:visited {
  text-decoration: none;
  color: #e5307d;
}

.link {
  color: black;
  font-weight:bold;
  text-decoration: underline; /* Apply underline */
  text-decoration-color: #e5307d; /* Set custom underline color */
  text-decoration-thickness: 2px; /* Set custom thickness */
  text-underline-offset: 3px; /* Offset the underline from the text */
}

.accordion-body {
  background-color: transparent;
}

.accordion .accordion-item {
  border: 2px solid #e5307d; /* Border color */
  border-radius: 10px; /* Rounded border */
  margin-bottom: 10px; /* Space between accordion items */
}

.accordion-header {
  border:0px;
  background-color: transparent !important;
}

.accordion-button {
  background-color: transparent;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-a {
  color: white;
  background-color: #e5307d;
}

.accordion-button:after {
  background-image: none;
  content: '\f0c4'; /* Custom icon (FontAwesome down arrow) */
  font-family: "Font Awesome 5 Free"; /* Ensure you have Font Awesome loaded */
  font-weight: 900; /* Use the solid style */
  transform: rotate(0deg);
  transition: transform 0.2s;
}

/* Rotate icon when expanded */
.accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(90deg);
}

.accordion-button:not(.collapsed) {
  color: white;
  font-weight: bold;
  background-color: #e5307d;
  border-radius: 6px 6px 0px 0px; 
}

.accordion .list-group-item {
  border: none; /* Removes borders around list group items */
  padding-left: 0; /* Removes left padding */
}

/* Ensure the container has relative positioning */
.carousel-container {
  position: relative;
  max-width: 600px; /* Adjust as needed */
  margin: auto; /* Center the container horizontally */
  overflow: hidden; /* Hide any overflow to ensure rounded corners are visible */
  border-radius: 15px; /* Adjust the radius as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visual effect */
}

/* Style the controls */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  bottom: 10px; /* Position controls at the bottom */
  top: auto; /* Override default top positioning */
  height: 40px; /* Adjust height as needed */
  width: 40px; /* Adjust width as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 50%; /* Round the controls to a circle */
  z-index: 10; /* Ensure controls are on top */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Position the previous button on the left */
.carousel-control-prev {
  left: 10px;
}

/* Position the next button on the right */
.carousel-control-next {
  right: 10px;
}

/* Style the icons inside controls */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 100%, 100%; /* Ensure icons fit the controls */
}